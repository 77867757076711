import React from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { CPF } from "cpf_cnpj"

const ExportCSV = ({ transactions, onlyCancelled, isLoading }) => {
  const [csvData, setCsvData] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const getPaymentMethod = (paymentMethod) => {
    const paymentMethods = {
      1: "Crédito",
      2: "Débito",
      3: "Dinheiro",
      5: "PIX"
    };

    return paymentMethods[paymentMethod] || null;
  };

  const headers = [
    { label: "Ações", key: "actions" },
    { label: onlyCancelled ? "Data Venda" : "Data", key: "createdAt" },
    onlyCancelled && { label: "Data do cancel.", key: "canceledAt" },
    { label: "Forma Pgto", key: "paymentMethod" },
    { label: "Valor", key: "amount" },
    { label: "Cód. Venda", key: "operatorRefCode" },
    { label: "Terminal", key: "terminalCode" },
    { label: "Bandeira", key: "cardBrandCode" },
    { label: "Holder", key: "cardHolder" },
    { label: "NSU", key: "cardAuthorizationCode" },
    { label: "Transação ID", key: "cardTransactionId" },
    { label: onlyCancelled ? "Operador Venda" : "Operador", key: "loggedUserDocument" },
    onlyCancelled && { label: "Operador Cancel.", key: "canceledByUser" },
    { label: "Status", key: "status" },
    { label: "Produtos", key: "products" }
  ].filter(Boolean);

  const generateData = () => {
    const data = transactions.map(t => ({
      actions: "-",
      createdAt: moment.utc(t.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      canceledAt: onlyCancelled ? moment.utc(t.canceledAt).format("DD/MM/YYYY HH:mm:ss") : "",
      paymentMethod: getPaymentMethod(t.paymentMethod),
      amount: `R$ ${parseFloat(t.amount).toLocaleString("pt-BR")}`,
      operatorRefCode: t.operatorRefCode,
      terminalCode: t.terminalCode || "-",
      cardBrandCode: t.cardBrandCode || "-",
      cardHolder: t.cardHolder || "-",
      cardAuthorizationCode: t.cardAuthorizationCode || "-",
      cardTransactionId: t.cardTransactionId || "-",
      loggedUserDocument: CPF.format(t.loggedUserDocument) || "-",
      canceledByUser: onlyCancelled ? CPF.format(t.canceledByUser) || "-" : "",
      status: t.canceledAt ? "Estornado" : "Pago",
      products: (t.products || []).map(p => `${p.name} (${p.count})`).join(", ")
    }));
    setCsvData(data);
    setIsReady(true);
  };

  return (
    <>
      {!isReady && (
        <button onClick={generateData} className="btn btn-primary" disabled={isLoading}>
          Iniciar exportação CSV
        </button>
      )}
      {isReady && (
        <CSVLink data={csvData} headers={headers} filename="transactions.csv"
          className="btn btn-success ml-2" disabled={isLoading} onClick={() => setIsReady(false)}>
          Baixar CSV
        </CSVLink>
      )}
    </>
  );
};

export default ExportCSV;
