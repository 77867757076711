import React from "react";

import Dashboard from "../dashboard";

import { useDispatch, useSelector } from "react-redux";

import Card from "../../components/card";
import Alert from "../../components/alert";

import { Row, Col, ButtonToolbar, ButtonGroup } from "react-bootstrap";

import { set, save, clearDevice, getOne, getAcquirers } from "./actions";

import { get as getMenus } from "../menus/actions";

import useMount from "../../helpers/useMount";

import Button from "../../components/button";

import InputText from "../../components/inputs/text";
import SwitchInput from "../../components/inputs/switch";
import MultiSelect from "../../components/multiSelect";
import SelectInput from "../../components/inputs/select";

import Form from "react-nonconformist";

function useStateAndDispatch() {
  const dispatch = useDispatch();
  const device = useSelector((state) => state.devices.device);
  const menus = useSelector((state) => state.menus.menus);
  const response = useSelector((state) => state.devices.response);
  const acquirers = useSelector((state) => state.devices.acquirers);
  const isLoading = useSelector((state) => state.isLoading[save]);

  return {
    device,
    menus,
    response,
    acquirers,
    isLoading,
    save: (params) => dispatch(save(params)),
    set: (params) => dispatch(set(params)),
    clearDevice: () => dispatch(clearDevice()),
    getOne: (params) => dispatch(getOne(params)),
    getMenus: (params) => dispatch(getMenus(params)),
    getAcquirers: (params) => dispatch(getAcquirers(params)),
  };
}

export default function EventSave({ history, match }) {
  const {
    device,
    menus,
    response,
    acquirers,
    isLoading,
    save,
    set,
    getOne,
    getMenus,
    getAcquirers,
  } = useStateAndDispatch();

  const screenType =
    match.path === "/events/:uuid/pos/:uuidDevice" ? "edit" : "view";

  useMount(() => {
    if (screenType === "edit") {
      getOne({ uuidDevice: match.params.uuidDevice });
    }
    getMenus({ uuid: match.params.uuid });
    getAcquirers();
  });

  const submit = async () => {
    await save({
      _id: device._id,
      eventId: match.params.uuid,
      name: device.name,
      menusIds: device.menusIds,
      acquirer: device.acquirer,
      isEnabled: device.isEnabled,
      isServiceFeeEnabled: device.isServiceFeeEnabled,
      serviceFee: parseFloat(device.serviceFee, 10) || 0,
      isQRCodeEnabled:
        device.isQRCodeEnabled !== void 0 ? device.isQRCodeEnabled : false,
    });

    history.push("/events/" + match.params.uuid + "/pos");
  };
  // console.log('page')
  // console.log(device)
  const header = device.hasTransaction ? "Ponto de Venda" : "Ponto de Venda";
  return (
    <Dashboard
      title={screenType === "edit" ? "Editar PDV" : "Novo PDV"}
      header={
        <ButtonToolbar className="justify-content-between">
          <ButtonGroup>
            <Button variant="secondary" to={`/events/${match.params.uuid}/pos`}>
              ←&nbsp;&nbsp;Voltar
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      }
    >
      <Alert variant="danger" show={response.status === "error"}>
        {response.message}
      </Alert>
      <Form values={device} onSubmit={submit} onChange={set}>
        {(connect, submit) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <Card header={<h3 className="mb-0">{header}</h3>} shadow>
              <Row>
                <Col sm={12} md={12}>
                  <Row>
                    {screenType === "edit" && (
                      <Col sm={12} md={3}>
                        <SwitchInput
                          {...connect("isEnabled")}
                          label="Status do PDV"
                          isDisable={device.hasTransaction}
                        />
                      </Col>
                    )}
                    <Col sm={12} md={2}>
                      <div style={{ display: "flex", gap: 20 }}>
                        <SwitchInput
                          {...connect("isServiceFeeEnabled")}
                          label="Taxa de Serviço"
                        />
                        <InputText
                          {...connect("serviceFee")}
                          label=" "
                          disabled={!device.isServiceFeeEnabled}
                          placeholder="%"
                          min={0}
                          max={100}
                          mask={"99%"}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <SwitchInput
                    {...connect("isQRCodeEnabled")}
                    label="Imprimir QRCode"
                    isDisable={device.hasTransaction}
                  />
                  <SelectInput
                    {...connect("acquirer")}
                    label={"Adquirente"}
                    value={device.acquirer}
                    options={acquirers}
                    isDisable={device.hasTransaction}
                    disable={device.hasTransaction}
                    required
                  />
                  <InputText
                    {...connect("name")}
                    label="Nome do PDV"
                    disabled={device.hasTransaction}
                    required
                  />
                  <MultiSelect
                    {...connect("menusIds")}
                    items={menus.data}
                    // bloqueia a alteração de cardápio
                    // disabled={device.hasTransaction}
                    selectedItems={device.menusIds}
                    required
                  />
                </Col>
              </Row>
            </Card>
            <div style={{ marginTop: 20 }}>
              <Alert variant="danger" show={response.status === "error"}>
                {response.message}
              </Alert>
              <Button
                isLoading={isLoading}
                // isDisable={device.hasTransaction}
                type="button"
                size="lg"
                disabled
                onClick={submit}
              >
                Salvar
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Dashboard>
  );
}
