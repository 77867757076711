import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Table, OverlayTrigger, Popover, Modal, Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { CPF } from "cpf_cnpj"
import toast, { Toaster } from "react-hot-toast"

import Card from "../../components/card"
import Button from "../../components/button"
import Dashboard from "../dashboard"
import Filters from "../filters"

import { getSalesAudit as getSalesAuditAction } from "./actions"
import isEmpty from "lodash/isEmpty"
import moment from "moment"

import * as transactionsAPI from "../../api/transactions"
import ExportCSV from "./salesAuditExport"

function useStateAndDispatch() {
  const dispatch = useDispatch()
  const data = useSelector(state => state.salesAudit.data)
  const isLoadingSalesAudit = useSelector(state => state.isLoading[getSalesAuditAction])

  return {
    data,
    isLoadingSalesAudit,
    getSalesAudit: params => dispatch(getSalesAuditAction(params)),
    dispatch
  }
}

export default function Sales({ history, match }) {
  const [isLoading, setIsLoading] = useState(false)
  const [showModalCancel, setshowModalCancel] = useState(false)
  const [showModalInfo, setshowModalInfo] = useState(false)
  const [transaction, setTransaction] = useState({})
  const [cancellationReason, setCancellationReason] = useState(null)
  const [currentFilters, setCurrentFilters] = useState({})
  const [isInvalidReason, setIsInvalidReason] = useState(false)

  let hideMenu = false

  let {
    data,
    getSalesAudit,
    isLoadingSalesAudit,
    dispatch
  } = useStateAndDispatch()

  let transactions = data.transactions;
  const onlyCancelled = data.onlyCancelled;

  const filter = filter => {
    const startAt = filter.StartAt || "01/01/2019"
    const startAtHour = filter.StartAtHour || "00:00"
    const endAt = filter.EndAt || "01/01/2099"
    const endAtHour = filter.EndAtHour || "00:00"
    const onlyCancelled = filter.OnlyCancelled || false

    const mapper = {
      uuid: (filter.CashierCPF && filter.CashierCPF.event) || match.params.uuid,
      cpf: (filter.CashierCPF && filter.CashierCPF.cashier) || "",
      terminalCode: (filter.CashierCPF && filter.CashierCPF.pos) || "",
      startAt: startAt + " " + startAtHour,
      endAt: endAt + " " + endAtHour,
      onlyCancelled
    }

    setCurrentFilters(mapper)
    getSalesAudit(mapper)
  }

  const getPaymentMethod = (paymentMethod) => {
    const paymentMethods = {
      1: "Crédito",
      2: "Débito",
      3: "Dinheiro",
      5: "PIX"
    };

    return paymentMethods[paymentMethod] || null;
  }

  const handleCancelTransaction = async (transaction) => {
    setIsLoading(true)
    try {
      if (isEmpty(cancellationReason)) {
        setIsLoading(false)
        setIsInvalidReason(true)
        return toast.error("Informe um motivo para o cancelamento!")
      }

      if (cancellationReason.length <= 10) {
        setIsLoading(false)
        setIsInvalidReason(true)
        return toast.error("Descreva mais sobre o motivo do cancelamento!")
      }

      transaction.cancellationReason = cancellationReason;
      const { data: result } = await transactionsAPI.cancel(transaction)

      if (!result.error) {
        handleClose()
        toast.success("Transação cancelada com sucesso!")
        dispatch(getSalesAuditAction(currentFilters))
      }
    } catch (err) {
      toast.error("Não foi possível cancelar essa transação, tente novamente mais tarde.")
    }
    setIsLoading(false)
  }

  const handleCancel = (transaction) => {
    setTransaction(transaction);
    setshowModalCancel(true);
  }

  const handleInfo = (transaction) => {
    setTransaction(transaction);
    setshowModalInfo(true);
  }

  const handleClose = () => {
    setshowModalCancel(false);
    setshowModalInfo(false);
    setCancellationReason(null);
  }

  const handleCancellationReason = event => {
    setCancellationReason(event.target.value);
    setIsInvalidReason(event.target.value.length <= 10)
  }

  const renderOptionsForTransactions = (transaction) => {
    return <>
      <i class="fa fa-trash text-red" style={{ cursor: "pointer", fontSize: 12, marginRight: 5 }} />
      <Link to="#" style={{ color: "#525f7f" }} onClick={() => handleCancel(transaction)}> Cancelar</Link>
    </>
  }

  const renderOptionsForCancelledTransactions = (transaction) => {
    return <>
      <i class="fa fa-info-circle" style={{ cursor: "pointer", fontSize: 12, marginRight: 5 }} />
      <Link to="#" style={{ color: "#525f7f" }} onClick={() => handleInfo(transaction)}> Mais informações</Link>
    </>
  }

  return (
    <Dashboard
      title={"Auditoria de vendas"}
      hideMenu={hideMenu}
      header={
        <Filters
          isLoading={isLoadingSalesAudit}
          history={history}
          onFilter={filter}
          filters={[
            { name: "CashierCPF", input: "CashierCPF", label: "CPF do Caixa", hideEvent: hideMenu, idEvent: match.params.uuid },
            { name: "StartAt", input: "Date", label: "Início" },
            { name: "StartAtHour", input: "Hour", label: "Horário" },
            { name: "EndAt", input: "Date", label: "Fim" },
            { name: "EndAtHour", input: "Hour", label: "Horário" },
            { name: "OnlyCancelled", input: "OnlyCancelled", label: "Somente transações canceladas", offText: "Não", onText: "Sim" }
          ]}
          title={"Auditoria de vendas"}
        />
      }
    >
      {isEmpty(transactions) ? "" : <div>
        <Row>
          <Col sm={12} style={{ marginTop: 30 }}>
            <Card isLoading={isLoadingSalesAudit} shadow header={
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Transações</h3>
                  <ExportCSV transactions={transactions || []} onlyCancelled={onlyCancelled} isLoading={isLoadingSalesAudit} />
                </div>
              }>
              <Table hover size="sm" className="align-items-center table-flush" responsive style={{ display: isEmpty(transactions) ? "none" : "table" }}>
                <thead className="thead-light">
                  <tr>
                    <th>Ações</th>
                    <th>{onlyCancelled ? "Data Venda" : "Data"}</th>
                    {onlyCancelled ? <th>Data do cancel.</th> : null}
                    <th>Forma Pgto</th>
                    <th>Valor</th>
                    <th>Cód. Venda</th>
                    <th>Terminal</th>
                    <th>Bandeira</th>
                    <th>Holder</th>
                    <th>NSU</th>
                    <th>Transação ID</th>
                    <th>{onlyCancelled ? "Operador Venda" : "Operador"}</th>
                    {onlyCancelled ? <th>Operador Cancel.</th> : null}
                    <th>Status</th>
                    <th>Produtos</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(transactions) && transactions.map(t => {
                    const popover = (
                      <Popover id="popover-positioned-left" title="">
                        <div style={{ width: 140, height: 40, display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {onlyCancelled ? renderOptionsForCancelledTransactions(t) : renderOptionsForTransactions(t)}
                        </div>
                      </Popover>
                    );

                    return (
                      <tr key={t._id}>
                        <td>
                          <OverlayTrigger trigger={['click','hover']} delay={{ show: 500, hide: 3000 }} placement="right" overlay={popover}>
                            <i className="ni ni-settings-gear-65 text-gray" style={{ cursor: "pointer" }} />
                          </OverlayTrigger>
                        </td>
                        <td>{moment.utc(t.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                        {onlyCancelled ? <td>{moment.utc(t.canceledAt).format("DD/MM/YYYY HH:mm:ss")}</td> : null}
                        <td>{getPaymentMethod(t.paymentMethod)}</td>
                        <td>R$ {parseFloat(t.amount).toLocaleString("pt-BR")}</td>
                        <td>{t.operatorRefCode}</td>
                        <td>{t.terminalCode || "-"}</td>
                        <td>{t.cardBrandCode || "-"}</td>
                        <td>{t.cardHolder || "-"}</td>
                        <td>{t.cardAuthorizationCode || "-"}</td>
                        <td>{t.cardTransactionId || "-"}</td>
                        <td>{CPF.format(t.loggedUserDocument) || "-"}</td>
                        {onlyCancelled ? <td>{CPF.format(t.canceledByUser) || "-"}</td> : null}
                        <td>{t.canceledAt ? "Estornado" : "Pago"}</td>
                        <td>
                          {(t.products || []).map(product => {
                            return <span key={product._id}>{product.name} ({product.count})<br/></span>
                          })}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </div>
      }
      <Modal show={showModalCancel} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancelar transação?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Gostaria realmente de cancelar essa transação?
          <div style={{ marginTop: 20, marginBottom: 30 }}>
            <Table hover className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Data Venda</th>
                  <th>Forma Pgto</th>
                  <th>Valor</th>
                  <th>Cód. Venda</th>
                  <th>Terminal</th>
                  <th>Bandeira</th>
                  <th>Holder</th>
                  <th>NSU</th>
                  <th>Card Code</th>
                  <th>Transação ID</th>
                  <th>Operador</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{moment.utc(transaction.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                  <th>{getPaymentMethod(transaction.paymentMethod)}</th>
                  <td>R$ {parseFloat(transaction.amount).toLocaleString("pt-BR")}</td>
                  <td>{transaction.operatorRefCode || "-"}</td>
                  <td>{transaction.terminalCode || "-"}</td>
                  <td>{transaction.cardBrandCode || "-"}</td>
                  <td>{transaction.cardHolder || "-"}</td>
                  <td>{transaction.cardAuthorizationCode || "-"}</td>
                  <td>{transaction.cardTransactionCode || "-"}</td>
                  <td>{transaction.cardTransactionId || "-"}</td>
                  <td>{CPF.format(transaction.loggedUserDocument) || "-"}</td>
                  <td>{transaction.canceledAt ? "Estornado" : "Pago"}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <span>Informe um motivo:</span>
          <Form.Control as="textarea" rows={3} style={{ marginTop: 20 }} onChange={handleCancellationReason} isInvalid={isInvalidReason} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Fechar Janela
          </Button>
          <Button isLoading={isLoading} variant="success" onClick={() => handleCancelTransaction(transaction)}>
            Cancelar Transação
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalInfo} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Informações sobre o cancelamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Motivo do cancelamento:</strong>
          <div style={{ marginTop: 10, marginBottom: 30 }}>
            {transaction.cancellationReason || 'Não informado'}
          </div>

          <strong>Dados da transação:</strong>
          <div style={{ marginTop: 20, marginBottom: 30 }}>
            <Table hover className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Data Venda</th>
                  <th>Data Cancel.</th>
                  <th>Forma Pgto</th>
                  <th>Valor</th>
                  <th>Cód. Venda</th>
                  <th>Terminal</th>
                  <th>Bandeira</th>
                  <th>Holder</th>
                  <th>NSU</th>
                  <th>Card Code</th>
                  <th>Transação ID</th>
                  <th>Operador Venda</th>
                  <th>Operador Cancel.</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{moment.utc(transaction.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                  <td>{moment.utc(transaction.canceledAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                  <th>{getPaymentMethod(transaction.paymentMethod)}</th>
                  <td>R$ {parseFloat(transaction.amount).toLocaleString("pt-BR")}</td>
                  <td>{transaction.operatorRefCode || "-"}</td>
                  <td>{transaction.terminalCode || "-"}</td>
                  <td>{transaction.cardBrandCode || "-"}</td>
                  <td>{transaction.cardHolder || "-"}</td>
                  <td>{transaction.cardAuthorizationCode || "-"}</td>
                  <td>{transaction.cardTransactionCode || "-"}</td>
                  <td>{transaction.cardTransactionId || "-"}</td>
                  <td>{CPF.format(transaction.loggedUserDocument) || "-"}</td>
                  <td>{CPF.format(transaction.canceledByUser) || "-"}</td>
                  <td>{transaction.canceledAt ? "Estornado" : "Pago"}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </Dashboard>
  )
}
